<header>
  <div class="" style="padding-bottom:10px">
    <div class="new_header">
      <div class="new_header_top">
        <div class="logo_area">
          <a href="/">
            <img src="assets/img/logo.png" alt="logo" />
          </a>
        </div>
        <div class="header_top_contact">
          <div class="header_top_contact_buttons">
            <a href="tel:38507200" class="btn_cta btn_cta_pink">
              <span>
                <iconify-icon icon="bxs:phone-call"></iconify-icon>
                <small>查詢及報名熱線</small>
              </span>
              <strong>3850 7200</strong>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=85238507222&text=你好，想詢問有關資料。&type=phone_number&app_absent=0" target="_blank" class="btn_cta btn_cta_green">
              <span>
                <iconify-icon icon="ic:baseline-whatsapp"></iconify-icon>
                <small>WhatsApp熱線</small>
              </span>
              <strong>3850 7222</strong>
            </a>
            <div class="al_mobile_bar">
              <a class="openMenuBtn" href="/contactus"
                ><iconify-icon icon="ph:phone-call"></iconify-icon
              ></a>
            </div>
          </div>
<!--
          <div class="header_top_contact_branch al_mb_hide">
            <a href="/contactus">
            <h4>
              <strong>紅磡總行</strong>
              <span>九龍紅磡鶴翔街1號維港中心第一座6樓606A </span>
            </h4>
            <ul>
              <li>星期一至五 <span>10:00 - 19:00</span></li>
              <li>星期六、日及公眾假期休息</li>
            </ul>

            </a>
          </div>
        -->
        </div>
        <div class="header_top_contact al_mb_hide">
          <div class="header_top_contact_buttons">
            <a href="tel:38507228" class="btn_cta btn_cta_oranch">
              <span>
                <iconify-icon icon="bxs:phone-call"></iconify-icon>
                <small>獨立包團</small>
              </span>
              <strong>3850 7228</strong>
            </a>
            <a href="https://api.whatsapp.com/send/?phone=85291375396&text=你好，想詢問有關包團資料。&type=phone_number&app_absent=0" target="_blank" class="btn_cta btn_cta_green">
              <span>
                <iconify-icon icon="ic:baseline-whatsapp"></iconify-icon>
                <small>WhatsApp包團</small>
              </span>
              <strong>9137 5396</strong>
            </a>
          </div>
<!--
          <div class="header_top_contact_branch">
            <a href="/contactus">
            <h4>
              <strong>觀塘分店</strong>
              <span> 九龍觀塘開源道68號觀塘廣場1樓106號舖 </span>
            </h4>
            <h4>
              <strong>旺角分店</strong>
              <span>
                旺角奶路臣街20號啟賢大廈地下7號舖<br>
                <small>(舊中旅社對面)</small>
              </span>
            </h4>
            </a>  
          </div>
        -->
        </div>
        <div class="header_top_search al_mb_hide">
          <div class="header_top_contact_buttons">
            <div class="header_top_search_top">
              <div class="search_websites_title">
                <iconify-icon icon="bxs:hand-right"></iconify-icon>
                <a href="/home">
                  morningstartravel.com.hk
                </a>
              </div>
              <div class="header_top_search_top" (click)="openFacebook()" style="cursor:pointer">
              <form action="#">
                <span>
                  <iconify-icon icon="ri:facebook-fill"></iconify-icon>
                </span>
                <span
                  id="search"
                  placeholder=" 星晨旅遊 MorningStar" 
                  style="color:grey;background-color:white; width:150px;font-size:14px"
                >星晨旅遊 MorningStar</span>
                <button >
                  <iconify-icon icon="fa-solid:search"></iconify-icon>
                </button>
              </form>
              </div>
            </div>
          </div>
<!--
          <div class="header_top_contact_branch  al_mb_hide">
            <a href="/contactus">
            <h4>
              <strong>荃灣分行</strong>
              <span>
                荃灣青山道264-298號南豐中心1樓A19鋪<br>
                <small>(寫字樓電梯口)</small>
              </span>
            </h4>
            <ul>
              <li>星期一至日及公眾假期</li>
              <li><span>10:30 - 19:30</span></li>
            </ul>
            </a>
          </div>
        -->
        </div>
      </div>
            <!-- header menu -->
            <div class="header_menu">
              <nav>
                <ul>
                  <li>
                    <a href="">主頁 </a>
                  </li>
                  <li><a href="tour?loc=9999&title=旅行團">旅行團 </a></li>
                  <li>
                    <a href="tour?loc=9999&title=熱賣精選">
                      熱賣精選
                    </a>
                  </li>
                  <li><a href="tour?loc=172&type=top">香港本地遊</a></li>
                  <li>
                    <a href="tour?loc=100&type=sub&title=郵輪">
                      郵輪
                    </a>
                  </li>
                  <li><a href="incentive">獨立包團<br>
                            遊學團</a></li>
                  <li><a href="tour?loc=100&type=sub&title=自由行<br>套票">自由行<br>套票 </a></li>
                  <li><a href="tour?loc=100&type=sub&title=旅遊手信">旅遊手信 </a></li>
                </ul>
              </nav>
            </div>
      


      <!-- mobile menu  -->

      <div class="al_mobile_menu">
        <div class="al_mobile_menu_content">
          <div class="header_top_contact">
            <div class="header_top_contact_buttons">
              <a href="tel:38507200" class="btn_cta btn_cta_pink">
                <i class="material-icons">add_shopping_cart</i>
                  <span>
                  <iconify-icon icon="bxs:phone-call"></iconify-icon>
                  <small>查詢及報名熱線</small>
                </span>
                <strong >3850 7200</strong>
              </a>
              <a href="tel:38507228" class="btn_cta btn_cta_green">
                <span>
                  <iconify-icon icon="ic:baseline-whatsapp"></iconify-icon>
                  <small>WhatsApp熱線</small>
                </span>
                <strong>3850 7222</strong>
              </a>
            </div>
            <div class="header_top_contact_branch">
              <h4>
                <strong>紅磡總行</strong>
                <span>九龍紅磡鶴翔街1號維港中心第一座6樓606-2室 </span>
              </h4>
              <ul>
                <li>星期一至五 <span>10:00 - 19:00</span></li>
                <li>星期六、日及公眾假期休息</li>
              </ul>
            </div>
          </div>

          <div class="header_top_contact">
            <div class="header_top_contact_buttons">
              <a href="#" class="btn_cta btn_cta_oranch">
                <span>
                  <iconify-icon icon="bxs:phone-call"></iconify-icon>
                  <small>獨立包團</small>
                </span>
                <strong>3850 7228</strong>
              </a>
              <a href="#" class="btn_cta btn_cta_green">
                <span>
                  <iconify-icon icon="ic:baseline-whatsapp"></iconify-icon>
                  <small>WhatsApp包團</small>
                </span>
                <strong>9137 5396</strong>
              </a>
            </div>

            <div class="header_top_contact_branch">
              <h4>
                <strong>旺角分店</strong>
                <span>
                  旺角奶路臣街20號故賢大廈7號舖
                  <small>(舊中旅社對面)</small>
                </span>
              </h4>
              <h4>
                <strong>官塘分店</strong>
                <span> 九龍觀塘開源道68號觀塘廣場1樓106號舖 </span>
              </h4>
            </div>
          </div>
          <div class="header_top_search">
            <div class="header_top_search_buttons">
              <div class="header_top_search_top">
                <div class="search_websites_title">
                  <iconify-icon icon="bxs:hand-right"></iconify-icon>
                  <a
                    href="https://morningstartravel.com.hk/"
                    target="_blank"
                  >
                    morningstartravel.com.hk
                  </a>
                </div>
                <form action="#">
                  <span>
                    <iconify-icon icon="ri:facebook-fill"></iconify-icon>
                  </span>
                  <input
                    type="search"
                    id="search"
                    placeholder="「星晨旅遊 MomingStar"
                  />
                  <button type="submit">
                    <iconify-icon icon="fa-solid:search"></iconify-icon>
                  </button>
                </form>
              </div>
              <ul>
                <li><a href="#">以上優惠受有關條款及細則約束</a></li>
                <li><a href="#">詳情請向分行職員查詢</a></li>
              </ul>
            </div>

            <div class="header_top_contact_branch">
              <h4>
                <strong>荃灣分行</strong>
                <span>
                  荃灣青山道264-298號南豐中心1樓A19鋪
                  <small>(商業電梯大堂對面)</small>
                </span>
              </h4>
              <!-- <ul>
              <li>星期一至五 10:00 - 19:00</li>
            </ul> -->
            </div>
          </div>

          <div class="al_mobile_close">
            <a href="#">
              <iconify-icon icon="ic:baseline-close"></iconify-icon
            ></a>
          </div>
        </div>
      </div>
      <!-- --  -->
    </div>
  </div>
</header>


<script>
  const openMenu = document.querySelector(".openMenuBtn");
  const closeMenu = document.querySelector(".al_mobile_close");
  const mobileMenu = document.querySelector(".al_mobile_menu");

  openMenu.addEventListener("click", (event) => {
    event.preventDefault();
    mobileMenu.classList.add("active_mobile");
    return false;
  });

  closeMenu.addEventListener("click", (event) => {
    event.preventDefault();
    mobileMenu.classList.remove("active_mobile");
    return false;
  });

  mobileMenu.addEventListener("click", (event) => {
    event.stopPropagation();
    const isClickInsideMenuContent = event.target.closest(
      ".al_mobile_menu_content"
    );
    if (!isClickInsideMenuContent) {
      mobileMenu.classList.remove("active_mobile");
    }
  });
</script>