import { Injectable } from '@angular/core';
import { routes } from '../routes/routes';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiResultFormat, bbmslPayment, responsePayment,lecture } from '../models/models';

@Injectable({
    providedIn: 'root',
})




export class DataService {
    constructor(private http: HttpClient) {}
    data: any;
    public responseData : any;


    public sendPayment(paymentData: any): Observable<apiResultFormat> {

//        const body = JSON.stringify(paymentData);
        let headers = new HttpHeaders({
            'content-type':'application/json',
        });

        let options = { headers,
        };

        console.log("Obs:"+paymentData);
       
        return this.http
        .get<apiResultFormat>('api/payment/sendPayment?payData='+paymentData)
        .pipe(
            map((res: apiResultFormat) => {
//                console.log("res:",res)
                return res;
            })

        );

    }


    public getCategoryList( ): Observable<apiResultFormat> {


       return this.http
        .get<apiResultFormat>('api/tour/readTourCategoryTop')
        .pipe(
            map((res: apiResultFormat) => {
//                console.log("res:",res)
                return res;
            })
        );

    }

    public getCategoryListSub(): Observable<apiResultFormat> {


        return this.http
         .get<apiResultFormat>('api/tour/readTourCategorySub')
         .pipe(
             map((res: apiResultFormat) => {
//                 console.log("res:",res)
                 return res;
             })
         );
 
     } 

     public getTourByCategory(location:number, catType:string, itinCode:string): Observable<apiResultFormat> {
        return this.http
        .get<apiResultFormat>('api/tour/readTourCategoryTour?catList='+location+'&catType='+catType+'&itinCode='+itinCode)
        .pipe(
            map((res: apiResultFormat)=> {
                console.log("Tour category:",res);
                return res;
            })
        )
     }

     public getTourDate(itinCode:string, type:string): Observable<apiResultFormat> {
        return this.http
        .get<apiResultFormat>('api/tour/readTourDate?itinCode='+itinCode+'&tourtype='+type)
        .pipe(
            map((res: apiResultFormat)=> {
                console.log("Tour date category:",res);
                return res;
            })
        )
     }


     
     public getPassportTypeInfo(): Observable<apiResultFormat> {
        return this.http
        .get<apiResultFormat>('api/tasan/getPassportTypeInfo')
        .pipe(
            map((res: apiResultFormat)=> {
                console.log("Tour date category:",res);
                return res;
            })
        )
     }

    
     public savePaxList(data:any): Observable<any> {
        console.log("data:",data);

        return this.http
        .post<any>('api/tour/saveNgPaxData',data)
        .pipe(
            map((res:any)=> {
                return res;
            })
        )
    }

    public sendCreateTour(data:any): Observable<any> {
        console.log("data:",data);
        let jsondata = '{"paxList":"'+data+'"}';

        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type':  'application/json'
            })
          };


        return this.http
        .post<any>('api/tasan/sendCreateTourBooking',jsondata, httpOptions)
        .pipe(
            map((res:any)=> {
                return res;
            })
        )
    }



     public getPaxData(uuid: string,bookingNumber: string): Observable<apiResultFormat> {
        return this.http
        .get<apiResultFormat>('api/tour/getPaxData?uuid='+uuid+'&bookingNumber='+bookingNumber)
        .pipe(
            map((res: apiResultFormat)=> {
                console.log("Pax Data:",res);
                return res;
            })
        )
     }
    
     public updatePaxData(uuid: string, bookingNumber: string): Observable<apiResultFormat> {
        return this.http
        .get<apiResultFormat>('api/tour/updatePaxData?uuid='+uuid+'&bookingNumber='+bookingNumber)
        .pipe(
            map((res: apiResultFormat)=> {
                console.log("Tour date category:",res);
                return res;
            })
        )
     }

     public getImageList(tourCode: string): Observable<any> {
        return this.http
        .get<any>('api/tour/getTourImage?tourCode='+tourCode)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getHolidayList(): Observable<any> {
        return this.http
        .get<any>('api/tour/getHoliday')
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    } 
    
    public getDiscountList(tourCode: string): Observable<any> {
        return this.http
        .get<any>('api/tour/getDiscountList?tourCode='+tourCode)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getDiscountCode(): Observable<any> {
        return this.http
        .get<any>('api/tour/getDiscountCode')
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getTourTaxList(tourCode: string): Observable<any> {
        return this.http
        .get<any>('api/tour/getTaxList?tourCode='+tourCode)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    } 

    public saveLecture(lecture: lecture): Observable<any> {
        return this.http
        .post<any>('api/tour/saveLecture',lecture)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getLectureCount(): Observable<any> {
        return this.http
        .get<any>('api/tour/getLectureCount')
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getBanner(bannerType:number): Observable<any> {
        return this.http
        .get<any>('api/tour/getBanner?bannerType='+bannerType)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getAddress(): Observable<any> {
        return this.http
        .get<any>('api/address/all')
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

    public getTripshoot(): Observable<any> {
        return this.http
        .get<any>(`api/tripshoot/all`)
        .pipe(
            map((res:any) => {
                return res;
            })
        )
    }

}
