import { Component,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

schemas: [ CUSTOM_ELEMENTS_SCHEMA ];

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {


}
